var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-1 pa-1"},[_c('v-card-title',{staticClass:"pa-1"},[_c('span',[_vm._v(_vm._s(_vm.$t("inventory.address")))])]),_c('v-card-text',{staticClass:"pb-1"},[_c('v-form',{ref:"addressCreateForm"},[_c('v-row',[_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-autocomplete',{staticClass:"pa-0",attrs:{"label":_vm.$t('Ombor'),"clearable":"","items":_vm.warehouses,"item-value":"id","item-text":"search","rules":[function (v) { return !!v || _vm.$t('input.required'); }],"hide-details":"","dense":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pa-1 ma-0",attrs:{"color":"white"}},[_c('span',{domProps:{"textContent":_vm._s(item.name)}})])]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.name)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.code)}})],1)]}}]),model:{value:(_vm.form.warehouse_id),callback:function ($$v) {_vm.$set(_vm.form, "warehouse_id", $$v)},expression:"form.warehouse_id"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-text-field',{attrs:{"label":_vm.$t('inventory.address'),"rules":[function (v) { return !!v || _vm.$t('input.required'); }],"hide-details":"","outlined":"","dense":""},model:{value:(_vm.form.address_name),callback:function ($$v) {_vm.$set(_vm.form, "address_name", $$v)},expression:"form.address_name"}})],1),_c('v-col',{staticClass:"pa-1",attrs:{"cols":"3"}},[_c('v-btn',{attrs:{"color":"success"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Saqlash")])],1)],1)],1)],1),_c('v-data-table',{staticClass:"ma-1",staticStyle:{"border":"1px solid #aaa"},attrs:{"dense":"","fixed-header":"","loading-text":_vm.$t('loadingText'),"no-data-text":_vm.$t('noDataText'),"height":_vm.screenHeight,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.items,"search":_vm.search,"item-key":"id","server-items-length":_vm.server_items_length,"options":_vm.dataTableOptions,"disable-pagination":true,"footer-props":{
        itemsPerPageOptions: [50, 100, 200],
        itemsPerPageAllText: _vm.$t('itemsPerPageAllText'),
        itemsPerPageText: _vm.$t('itemsPerPageText'),
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
      }},on:{"update:options":function($event){_vm.dataTableOptions=$event},"update:page":_vm.updatePage,"update:items-per-page":_vm.updatePerPage},scopedSlots:_vm._u([{key:"body.prepend",fn:function(){return [_c('tr',{staticClass:"py-0 my-0"},[_c('td',{staticClass:"py-0 my-0 dense"}),_c('td',{staticClass:"py-0 my-0 dense"},[_c('v-autocomplete',{staticClass:"py-0",attrs:{"clearable":"","items":_vm.warehouses,"hide-details":"","item-value":"id","dense":""},on:{"change":function($event){return _vm.getList()}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.name))]}},{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.code + '-' + item.name)}})],1)]}}]),model:{value:(_vm.filter.warehouse_id),callback:function ($$v) {_vm.$set(_vm.filter, "warehouse_id", $$v)},expression:"filter.warehouse_id"}})],1),_c('td',{staticClass:"py-0 my-0 dense"},[_c('v-text-field',{attrs:{"hide-details":"","clearable":""},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.getList()}},model:{value:(_vm.filter.address_name),callback:function ($$v) {_vm.$set(_vm.filter, "address_name", $$v)},expression:"filter.address_name"}})],1),_c('td',{staticClass:"py-0 my-0 dense"})])]},proxy:true},{key:"item.id",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.items .map(function (x) { return x.id; }) .indexOf(item.id) + _vm.from)+" ")]}},{key:"item.warehouse_id",fn:function(ref){
      var item = ref.item;
return [_vm._v(_vm._s(item.warehouse ? item.warehouse.name : ""))]}},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-btn',{attrs:{"color":"blue","small":"","text":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"color":"red","small":"","text":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}])})],1),_c('v-dialog',{attrs:{"width":"300","hide-overlay":""},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('v-card',{attrs:{"color":"primary","dark":""}},[_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t("loadingText"))+" "),_c('v-progress-linear',{staticClass:"mb-0",attrs:{"indeterminate":"","color":"white"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }